(function(){
	class Modal {
		constructor(overlay){
			this.overlay = overlay
			const btnClose = overlay.querySelector('.modal-close')
			btnClose.addEventListener('click', this.close.bind(this))
			overlay.addEventListener('click', e => {
				if (e.srcElement.id === this.overlay.id) this.close()
			})
		}
		async open(page){
			let request = await fetch(`/assets/projects/${page}.html`)
			if (request.ok){
				let content = await request.text()
				
				this.overlay.querySelector('.modal-content').innerHTML = content
				document.querySelector('body').classList.add('modal-open')
				this.overlay.classList.remove('is-hidden')
				window.history.replaceState({},null,`?details=${page}${window.location.hash}`)
			}
		}
		async close() {
			document.querySelector('body').classList.remove('modal-open')
			window.history.replaceState({},null,`${window.location.hash}`)
			this.overlay.classList.add('is-hidden')
		}
	}
	const modal = new Modal(document.querySelector('#modal-overlay'))
	window.openModal = modal.open.bind(modal)
	
	let search = new URLSearchParams(window.location.search)
	if (search.has('details')) window.openModal(search.get('details'))
	
	if ('serviceWorker' in navigator) {
		window.addEventListener('load', ()=>{
			navigator.serviceWorker.register('/service-worker.min.js').then(registration=>{
				// Registration was successful
				console.debug('ServiceWorker registration successful with scope: ', registration.scope)
			})
		})
	}
	
	if ('IntersectionObserver' in window && 'replaceState' in window.history){
		let observer = new IntersectionObserver(entries=>{
			entries.forEach(entry=>{
				if (entry.isIntersecting){
					window.history.replaceState({},null,`${window.location.search}#${entry.target.id}`)
				}
			})
		}, {
			rootMargin: '0px',
			threshold: 0.75
		})
		document.querySelectorAll('section').forEach(section=>{
			observer.observe(section)
		})
	}
})()